import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddTreeNurseriesForm from "./AddTreeNurseriesForm";

const AddTreeNurseries = () => {
  const { t } = useTranslation();
  const { fetchData: addTreeOrigin, response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/origins/new/",
    "POST",
    false,
    "",
    true,
    true
  );

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree nurseries.Add tree nursery")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          { name: t("Tree nurseries.Add tree nursery"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddTreeNurseriesForm
            send={addTreeOrigin}
            sendResponse={response}
            textButton={t("Tree nurseries.Add nursery")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add incident.svg"
            alt="Add incident name"
            className="mt-4 ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default AddTreeNurseries;
