import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";
import { ResponsibleRecord } from "../../../type/responsibleRecord";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const ResponsibleTableItem: React.FC<{
  responsible: ResponsibleRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ responsible, lastIndex, fetchData: propFetchData }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/responsible/${responsible.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (responsible.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(
        t(
          "Message.You can’t delete the responsible type. It is used in a tree register or it has a responsible person."
        )
      );
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  const active =
    i18n.language === "en"
      ? responsible.is_active_transes.en
      : responsible.is_active_transes.es;
  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "en"
          ? responsible.category_transes.en
          : responsible.category_transes.es}
      </TableCell>
      <TableCell>{responsible.type}</TableCell>
      <TableCell>
        <p
          className={`${
            responsible.is_active_transes.en === "Active"
              ? "bg-ph-toggle-bg-green text-ph-toggle-text-green"
              : "bg-ph-toggle-bg-red text-ph-red-warning"
          } text-center w-16 py-1 rounded-xl`}
        >
          {active}
        </p>
      </TableCell>

      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`/setting/responsible/edit-responsible/${responsible.slug}`}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Tree register responsible.Responsible type")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default ResponsibleTableItem;
