import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PlantingList from "../type/PlantingList";

type ImportFilter = {
  planting: PlantingList | null | undefined;
};

const initialState: {
  filterItem: ImportFilter;
  filterSet: boolean;
  deleteFilter: "planting" | "";
} = {
  filterItem: {
    planting: null,
  },
  filterSet: false,
  deleteFilter: "",
};

const importFilterSlice = createSlice({
  name: "importFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<ImportFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removePlanting: (state) => {
      state.filterItem.planting = null;
      state.deleteFilter = "planting";
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.planting = null;
      state.deleteFilter = "";
    },
    isFilterApplied: (state) => {
      if (!state.filterItem.planting) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const importFilterAction = importFilterSlice.actions;
export default importFilterSlice;
