import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import Loading from "../../Global/Loading";
import AddTreeSuppliesForm from "../AddTreeSupplies/AddTreeSuppliesForm";
import SpeciesList from "../../../type/speciesList";
import i18n from "../../../i18n";

const EditTreeSupplies = () => {
  const { slug, inventory } = useParams<any>();
  const { t } = useTranslation();
  const [species, setSpecies] = useState<SpeciesList[]>([]);

  const { fetchData: editTreeSupply, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/origins/supply/${slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );
  const { response: speciesRes } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/species/all/`,
    "GET",
    true,
    "",
    true,
    false
  );
  const {
    response: getEditResponse,
    fetchData: getSupplies,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/origins/supply/${slug}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );
  const { response: breadcrumb } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/origins/${inventory}/inventory_tracking/breadcrumb_data`,
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (speciesRes && speciesRes.data) {
      setSpecies(speciesRes.data);
    }
  }, [speciesRes]);


  useEffect(() => {
    getSupplies({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree inventory.Edit supply tree")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          {
            name: `${i18n.language === "en"
                ? breadcrumb?.data.name_transes.en === ""
                  ? breadcrumb?.data.name_transes.es
                  : breadcrumb?.data.name_transes.en
                : breadcrumb?.data.name_transes.es
              }(${breadcrumb?.data.code})`,
            url: "",
          },
          {
            name: t("Tree inventory.Tree inventory tracking"),
            url: `/setting/nurseries/inventory-tracking/${inventory}`,
          },
          { name: t("Tree inventory.Supply trees"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddTreeSuppliesForm
              send={editTreeSupply}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
              inventory={inventory}
              species={species}
            />
          </div>
          <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
            <img
              src="/img/Botanical families.svg"
              alt="Edit incident"
              className="mt-4 ml-2 xl:ml-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default EditTreeSupplies;
