import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TaggingToolFilterSet, TaggingToolURL } from "../../../store";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import GenericTable from "../../Global/GenericTable";
import useAxios from "../../../hook/useAxios";
import { TaggingToolRecord } from "../../../type/taggingToolRecord";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import TaggingToolFilterBox from "./TaggingToolFilterBox";
import TaggingToolTableItem from "./TaggingToolTableItem";
import TaggingToolAppliedFilter from "./TaggingToolAppliedFilter";

const TaggingTool = () => {
  const [taggingToolList, setTaggingToolList] = useState<TaggingToolRecord[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const url = useSelector(TaggingToolURL);
  const isFilter = useSelector(TaggingToolFilterSet);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setTaggingToolList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Tagging tool.Tagging tool")}
        </h1>
        <Link
          to="/tools/tagging-tool/add-tag"
          className="green-btn px-[23px] py-[10px] text-sm"
        >
          {t("Tagging tool.Generate new tags")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Import register.Tools"), url: "" },
          { name: t("Tagging tool.Tagging tool"), url: "" },
        ]}
      />
      <TaggingToolFilterBox />
      {isFilter && <TaggingToolAppliedFilter />}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          handleChange={handleChange}
          dataList={taggingToolList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Community.Community"), value: "community" },
            { name: t("Tagging tool.Tag"), value: "tag" },
            { name: t("Tagging tool.Responsible"), value: "responsible_name" },
            { name: t("Tagging tool.Nursery"), value: "nursery" },
            { name: t("Tagging tool.Species"), value: "species" },
            { name: t("Tagging tool.Used"), value: "used" },
          ]}
          renderItem={(TaggingTool, fetchData, lastIndex) => (
            <TaggingToolTableItem
              taggingTool={TaggingTool}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
    </div>
  );
};

export default TaggingTool;
