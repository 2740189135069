import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import { toast } from "react-toastify";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";
import { TreeNurseriesRecord } from "../../../type/treeNurseries";
import NurseriesActionItem from "./NurseriesActionItem";
import InfoPopoverNursery from "./InfoPopoverNursery";

const TreeNurseriesTableItem: React.FC<{
  nurseries: TreeNurseriesRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ nurseries, fetchData: propFetchData, lastIndex }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${nurseries.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const deleteAction = () => {
    if (nurseries.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(
        t(
          "Message.You can’t delete the tree nursery. It is used in a tree register or tag."
        )
      );
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  return (
    <Fragment>
      <TableCell align="left">{nurseries.code}</TableCell>
      <TableCell align="left" width="190px">
        {i18n.language === "en"
          ? nurseries.name_transes?.en === ""
            ? nurseries.name_transes?.es
            : nurseries.name_transes?.en
          : nurseries.name_transes?.es}
      </TableCell>
      <TableCell align="left">{nurseries.capacity}</TableCell>
      <TableCell align="left">{nurseries.community}</TableCell>
      <TableCell align="left" width="200px">
        {nurseries.available_trees}
      </TableCell>
      <TableCell align="left" width="200px">
        <span className="flex flex-row items-center">
          {nurseries.nursery_responsible}{" "}
          {nurseries.responsible_phone ? (
            <InfoPopoverNursery
              index={index}
              scroll={scroll}
              phone={nurseries.responsible_phone}
            />
          ) : null}{" "}
        </span>
      </TableCell>
      <TableCell align="right">
        <NurseriesActionItem
          delete={true}
          suppliesLink={`/setting/nurseries/tree-supplies/${nurseries.slug}`}
          inventoryLink={`/setting/nurseries/inventory-tracking/${nurseries.slug}`}
          editLink={`/setting/nurseries/edit-nursery/${nurseries.slug}`}
          deleteAction={deleteAction}
          type={nurseries.type}
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Tree nurseries.Nursery")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default TreeNurseriesTableItem;
