import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

const DownloadTemplateImport: React.FC<{
  closePopOver: () => void;
}> = ({ closePopOver }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
      <div className="bg-white w-full h-fit p-8 sm:w-[731px] rounded-md shadow-md">
        <div className="flex justify-between  mb-6">
          {" "}
          <h2 className="font-semibold text-default-text">
            {t("Import register.Download register template")}
          </h2>
          <button onClick={closePopOver}>
            <FontAwesomeIcon className="text-ph-grayD w-4" icon={faXmark} />
          </button>
        </div>

        <div className="bg-ph-bg-white h-[400px] overflow-y-scroll p-2 rounded-md text-sm text-default-text">
          <h3 className="font-medium">
            {t("Import register.Import CSV file rules")}
          </h3>
          <ol className="list-decimal ml-5">
            <li className="mt-[10px]">{t("Import register.1")}</li>
            <li className="mt-[10px]">{t("Import register.2")}</li>
            <li className="mt-[10px]">{t("Import register.3")}</li>
            <li className="mt-[10px]">{t("Import register.4")}</li>
            <li className="mt-[10px]">{t("Import register.5")}</li>
            <li className="mt-[10px]">{t("Import register.6")}</li>
            <li className="mt-[10px]">{t("Import register.7")}</li>
            <li className="mt-[10px]">{t("Import register.8")}</li>
            <li className="mt-[10px]">{t("Import register.9")}</li>
            <li className="mt-[10px]">{t("Import register.10")}</li>
            <li className="mt-[10px]">{t("Import register.11")}</li>
            <li className="mt-[10px]">{t("Import register.13")}</li>
            <li className="mt-[10px]">{t("Import register.12")}</li>
          </ol>
        </div>
        <div className="flex justify-between  mt-6">
          <a
            href={
              process.env.REACT_APP_API_URL +
              "/static/media/latest-import-register-file.csv"
            }
            className="flex items-center"
          >
            <img
              alt="img"
              src="/img/file-excel.svg"
              className="w-[16px] h-[22px] mr-3"
            />
            <p className="text-default-text text-sm">
              {t("Import register.Latest import register file")}
            </p>
          </a>
          <a
            href={
              process.env.REACT_APP_API_URL +
              "/static/media/latest-import-register-file.csv"
            }
            className="float-right text-sm text-ph-btn underline"
          >
            {t("Tree Species.Download")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadTemplateImport;
