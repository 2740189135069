import { Fragment, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import AddOrganizerName from "./AddOrganizerName";
import { colors } from "../../../styles/colors";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon sx={{ color: colors.green_checkbox }} fontSize="small" />
);

const Orginizer: React.FC<{
  selectOptions: {
    department: Department[];
    psa: Department[];
    ins: Department[];
    ind: Department[];
  };
  orginizer: { type: string; collaborators: Department[] };
  index: number;
  addOrginizer: (type: string, value: Department) => void;
  typeChange: (index: number, value: string) => void;
  nameChange: (index: number, value: Department[]) => void;
  showError: boolean;
  selectedTypes: string[];
}> = ({
  selectOptions,
  orginizer,
  typeChange,
  nameChange,
  showError,
  index,
  addOrginizer,
  selectedTypes,
}) => {
  const [addCollaboratorName, isAddCollaboratorName] = useState<boolean>(false);
  const { t } = useTranslation();

  const addNewOrginizerName = (type: string, value: Department) => {
    addOrginizer(type, value);
    const name = [...orginizer.collaborators, value];
    nameChange(index, name);
  };
  const filteredOptions = [
    { value: "psa", label: t("Register.PSA group") },
    { value: "ind", label: t("Register.Individual") },
    { value: "ins", label: t("Register.Institution") },
  ].filter(
    (option) =>
      !selectedTypes.includes(option.value) || option.value === orginizer.type
  );

  const getOptionsByType = (type: string) => {
    switch (type) {
      case "ind":
        return selectOptions.ind;
      case "psa":
        return selectOptions.psa;
      case "ins":
        return selectOptions.ins;
      default:
        return [];
    }
  };

  return (
    <div className="flex flex-wrap justify-between mt-5">
      <div className="w-full sm:w-1/2 md:pr-2">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Tree Species.Type")} *
        </p>
        <FormControl style={{ width: "100%" }}>
          <InputLabel shrink={false}>
            {orginizer.type === "" && t("Tree Species.Type")}
          </InputLabel>
          <Select
            sx={{
              border: "1px solid #eeecec",
              borderRadius: "4px",
              "& fieldset": { border: "none" },
            }}
            style={{ height: "48px" }}
            IconComponent={ExpandMoreIcon}
            variant="outlined"
            value={orginizer.type}
            onChange={(e) => {
              const newType = e.target.value;
              typeChange(index, newType);
            }}
          >
            {filteredOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showError && !orginizer.type && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
      </div>
      <div className="w-full sm:w-1/2 sm:pl-2 mt-7 sm:mt-0">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Tree Species.Name")} *
        </p>

        <Autocomplete
          popupIcon={
            <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />
          }
          disabled={orginizer.type === "" ? true : false}
          multiple={true}
          limitTags={1}
          id="checkboxes-tags-demo"
          options={getOptionsByType(orginizer.type)}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.slug === value.slug}
          renderOption={(prop, option, { selected }) => (
            <Fragment>
              {option.slug === getOptionsByType(orginizer.type)[0].slug && (
                <div className="px-7 border-b border-ph-iborder">
                  <button
                    onClick={() => isAddCollaboratorName(true)}
                    className="text-sm w-full items-center flex justify-between text-ph-btn py-2 "
                  >
                    {t("Plantings.Create new organizer")}
                    <FontAwesomeIcon className="ml-2" icon={faPlus} />
                  </button>
                </div>
              )}
              <li {...prop} key={option.slug}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            </Fragment>
          )}
          sx={{
            width: "100%",
            [`& .${autocompleteClasses.popupIndicator}`]: {
              transform: "none",
            },
            "& .Mui-focused": {
              outline: "1px solid #efbf07",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                border: "1px solid #eeecec",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
              placeholder={
                orginizer.collaborators?.length === 0
                  ? t("Tree Species.Name") + ""
                  : ""
              }
            />
          )}
          onChange={(event, newValue) => {
            nameChange(index, newValue);
          }}
          value={orginizer.collaborators}
        />
      </div>
      {addCollaboratorName && (
        <AddOrganizerName
          addOrganizer={addNewOrginizerName}
          closeAddOrganizer={() => {
            isAddCollaboratorName(false);
          }}
          openModal={addCollaboratorName}
          organizerType={orginizer.type}
        />
      )}
    </div>
  );
};

export default Orginizer;
