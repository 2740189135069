import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import InfoPopover from "./InfoPopover";
import { RegisterRecord } from "../../../type/register";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import ActionItem from "../../Global/ActionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faSkull,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { profileInfo } from "../../../store";
import IncidentInfo from "./IncidentInfo";
import { faLocationExclamation } from "@fortawesome/pro-regular-svg-icons";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const RegisterTableItem: React.FC<{
  registerItem: RegisterRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ registerItem, fetchData: propFetchData, lastIndex }) => {
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const { t, i18n } = useTranslation();

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/${registerItem.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const speciesEn =
    registerItem.species_name_transes.en === ""
      ? registerItem.species_name_transes.es
      : registerItem.species_name_transes.en;

  const originEn =
    registerItem.origin_transes.en === ""
      ? registerItem.origin_transes.es
      : registerItem.origin_transes.en;

  const index = lastIndex >= 0 ? true : false;

  const scroll = lastIndex === 0 ? true : false;

  return (
    <Fragment>
      <TableCell>
        <div className="w-[100px] flex flex-row items-center">
          {registerItem.is_out_of_country && (
            <span
              title={`${t(
                "Register.The submitted location is out of the country."
              )}`}
            >
              <FontAwesomeIcon
                className="text-default-text mr-2"
                icon={faLocationExclamation}
              />
            </span>
          )}
          <Link
            className="hover:text-ph-hover"
            to={`/registers/view-register/${registerItem.slug}`}
          >
            {registerItem.tag}{" "}
          </Link>
          {registerItem.is_dead && (
            <FontAwesomeIcon
              className="text-default-text pl-3"
              title={`${t("Growth history.Dead")}`}
              icon={faSkull}
            />
          )}
        </div>
      </TableCell>
      <TableCell>{registerItem.date_of_planting}</TableCell>
      <TableCell align="left">
        {i18n.language === "en"
          ? speciesEn
          : registerItem.species_name_transes.es}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "en" ? originEn : registerItem.origin_transes.es}
      </TableCell>
      <TableCell>
        <div className=" flex items-center">
          {" "}
          {registerItem.responsible_name}
          {registerItem.description_transes.es !== "" && (
            <InfoPopover
              index={index}
              scroll={scroll}
              desc={registerItem.description_transes}
            />
          )}
        </div>
      </TableCell>
      <TableCell>
        {i18n.language === "en"
          ? registerItem.responsible_category_transes.en
          : registerItem.responsible_category_transes.es}
      </TableCell>
      <TableCell>{registerItem.responsible_type}</TableCell>
      <TableCell>
        {registerItem.incidents.length > 0 && (
          <IncidentInfo
            incidents={registerItem.incidents}
            index={index}
            scroll={scroll}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {registerItem.is_replanting ? (
          <FontAwesomeIcon
            className="text-lg text-ph-hover"
            icon={faCircleCheck}
          />
        ) : (
          <FontAwesomeIcon
            className="text-lg text-ph-red-warning"
            icon={faCircleXmark}
          />
        )}
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={profile.role === "O" ? false : true}
          deleteAction={deleteAction}
          editLink={`/registers/edit-register/${registerItem.slug}`}
          view={t("Register.View register")}
          viewLink={`/registers/view-register/${registerItem.slug}`}
          viewHistory={t("Register.View growth history")}
          historyLink={`/registers/growth-history/${registerItem.slug}`}
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Register.Register")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default RegisterTableItem;
