import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DistributeTreeForm from "../AddDistributeTree/DistributeTreeForm";
import BreadCrumb from "../../../../Global/BreadCrumb";
import useAxios from "../../../../../hook/useAxios";
import { useEffect } from "react";
import Loading from "../../../../Global/Loading";
import i18n from "../../../../../i18n";

const EditDistributeTree = () => {
  const { t } = useTranslation();
  const { slug, inventory } = useParams<any>();

  const { response: breadcrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${inventory}/inventory_tracking/breadcrumb_data`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: editDistribute, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/distribute/${slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const {
    response: getEditResponse,
    fetchData: getDistribute,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/distribute/${slug}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    getDistribute({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree inventory.Edit distribute trees")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          {
            name: `${
              i18n.language === "en"
                ? breadcrumb?.data.name_transes.en === ""
                  ? breadcrumb?.data.name_transes.es
                  : breadcrumb?.data.name_transes.en
                : breadcrumb?.data.name_transes.es
            }(${breadcrumb?.data.code})`,
            url: "",
          },
          {
            name: t("Tree nurseries.Tree inventory tracking"),
            url: `/setting/nurseries/inventory-tracking/${inventory}`,
          },
          {
            name: t("Tree inventory.Edit distribute trees"),
            url: "",
          },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <DistributeTreeForm
              nurseryCode={breadcrumb?.data.code}
              send={editDistribute}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              id={inventory}
            />
          </div>
          <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
            <img
              src="/img/Add Event.svg"
              alt="addEvent"
              className="mt-4 ml-2 xl:ml-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default EditDistributeTree;
