import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import { Link } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import { TableThem } from "../../../styles/TableThem";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import GenericTable from "../../Global/GenericTable";
import HelpVideosTableItem from "./HelpVideosTableItem";
import { helpVideos } from "../../../type/helpVideos";

const HelpVideos = () => {
  const [videoList, setVideoList] = useState<helpVideos[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();

  const {
    fetchData: getVideos,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/help_videos/?ordering=${ordering}&page=${page}&search=${sendSearch}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response !== null) {
      setCount(response.data.count);
      setVideoList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [sendSearch]);

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center mt-5">
        <div>
          <h1 className="font-semibold text-default-text text-[16px] inline-block mr-5">
            {t("Help videos.Help videos")}
          </h1>
        </div>
        <Link
          to="/tools/help-videos/add-videos"
          className="green-btn px-[23px] py-[10px] text-sm font-medium"
        >
          {t("Help videos.Add help videos")}
        </Link>
      </div>

      <BreadCrumb
        breadcrumb={[
          { name: t("Help videos.Tools"), url: "" },
          {
            name: t("Help videos.Help videos"),
            url: "",
          },
        ]}
      />

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md overflow-">
        <GenericTable
          search={sendSearch}
          countData={count}
          handleChange={handleChange}
          dataList={videoList}
          fetchData={() => {
            getVideos({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Help videos.Title"), value: "title" },
            {
              name: t("Help videos.Page"),
              value: "page",
            },
            {
              name: t("Help videos.Video URL"),
              value: "",
            },
          ]}
          renderItem={(importVideo, fetchData, lastIndex) => (
            <HelpVideosTableItem
              lastIndex={lastIndex}
              helpVideos={importVideo}
              fetchData={fetchData}
            />
          )}
          showAction={true}
        />
        <br />
      </div>
    </div>
  );
};

export default HelpVideos;
