import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import { Planting } from "../../../type/planting";
import WorkshopParticipants from "../../Workshops/WorkshopList/WorkshopParticipants";
import InfoPopover from "../../Register/RegisterList/InfoPopover";
import TreesDetail from "./TreeDetail";
import OrginizerName from "./OrginizerName";
import { toast } from "react-toastify";
import PlantingActionMenu from "./PlantingActionMenu";
import { Link } from "react-router-dom";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const PlantingTableItem: React.FC<{
  planting: Planting;
  fetchData: () => void;
  lastIndex: number;
}> = ({ planting, fetchData: propFetchData, lastIndex }) => {
  const { t, i18n } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${planting.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (planting.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(
        t("Message.You can’t delete the event. It contains some registers.")
      );
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const name =
    planting.name_transes.en === ""
      ? planting.name_transes.es
      : planting.name_transes.en;

  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  return (
    <Fragment>
      <TableCell align="left">
        <Link
          className="hover:text-ph-hover"
          to={`/plantings/view-planting/${planting.slug}`}
        >
          <div
            title={i18n.language === "en" ? name : planting.name_transes.es}
            className="name-overflow"
          >
            {i18n.language === "en" ? name : planting.name_transes.es}
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">{planting.date}</TableCell>
      <TableCell align="left">
        <div className=" flex items-center">
          {" "}
          {planting.department}
          {planting.description_transes.es !== "" && (
            <InfoPopover
              index={index}
              scroll={scroll}
              desc={planting.description_transes}
            />
          )}
        </div>
      </TableCell>
      <TableCell align="left">{planting.municipality}</TableCell>
      <TableCell align="left">{planting.community}</TableCell>
      <TableCell align="left">
        <OrginizerName
          organizers={planting.organizers}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="left">{planting.planted_trees}</TableCell>
      <TableCell align="left">
        {planting.registered_trees > 0 ? (
          <div className="flex items-center">
            <TreesDetail
              num={planting.registered_trees}
              trees={planting.trees_info}
              index={index}
              scroll={scroll}
            />
            {planting.registered_trees > planting.planted_trees && (
              <InfoPopover
                index={index}
                scroll={scroll}
                reg={true}
                desc={{
                  en: "The registered trees are more than planted trees",
                  es: "Los árboles registrados son más que los plantados",
                }}
              />
            )}
          </div>
        ) : (
          <p className="ml-[6px]">0</p>
        )}
      </TableCell>
      <TableCell align="left">
        <WorkshopParticipants
          totalNum={planting.participants_total}
          others={planting.other_participants}
          psaS={planting.psa_students}
          psaT={planting.psa_tutors}
          schoolS={planting.school_students}
          schoolT={planting.school_tutors}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="center">
        <PlantingActionMenu
          deleteAction={deleteAction}
          plantedTrees={planting.planted_trees}
          registeredTrees={planting.registered_trees}
          slug={planting.slug}
          initials={planting?.community_initials}
          cSlug={planting?.community_slug}
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Plantings.Planting")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default PlantingTableItem;
