import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import { ScientificName } from "../../../type/scientificName";
import ActionItem from "../../Global/ActionItem";
import ScientificNamesTooltip from "./ScientificNamesTooltip";
import { toast } from "react-toastify";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";

const ScientificNameTableItem: React.FC<{
  names: ScientificName;
  fetchData: () => void;
  lastIndex: number;
}> = ({ names, fetchData: propFetchDta, lastIndex }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/scientific_names/${names.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR !== null) {
      isShowDelete(false);
      propFetchDta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (names.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(
        t("Message.You can’t delete the species. It is used in planting.")
      );
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const name =
    names.species_name_transes.en === ""
      ? names.species_name_transes.es
      : names.species_name_transes.en;
  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "en" ? name : names.species_name_transes.es}
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          {i18n.language === "en"
            ? names.scientific_name_transes[0].en ||
              names.scientific_name_transes[0].es
            : names.scientific_name_transes[0].es}
          {names.scientific_name_transes.length > 1 && (
            <ScientificNamesTooltip names={names.scientific_name_transes} />
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`/setting/scientific-names/edit-scientific-name/${names.slug}`}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Tree Species.Scientific name")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default ScientificNameTableItem;
