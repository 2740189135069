import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { profileInfo } from "../../../store";
import { useSelector } from "react-redux";

const InventoryActionItem: React.FC<{
  editLink?: string;
  operation: string;
  deleteAction: () => void;
  delete: boolean;
  editSupply: string;
  editDistribute: string;

  notEditable?: boolean;
}> = ({
  editLink,
  delete: propsDelete,
  deleteAction,
  operation,
  editSupply,
  editDistribute,
}) => {
  const [showAction, isShowAction] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const profile = useSelector(profileInfo);
  const { t } = useTranslation();
  const { slug } = useParams<any>();

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const toggleAction = () => {
    isShowAction(!showAction);
  };

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowAction(false);
    }
  };
  return (
    <div className="relative">
      <button
        ref={btnRef}
        onClick={toggleAction}
        className="text-ph-gray px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {showAction && (
        <div
          ref={divRef}
          className={`absolute z-10 right-1 grid-flow-col text-left shadow-lg rounded-md bg-white pl-4 w-fit py-3 `}
        >
          <Fragment>
            <div
              className={
                operation === "Supplied" || operation === "Suministrado"
                  ? "w-[150px]"
                  : "w-[170px]"
              }
            >
              <>
                {operation === "Supplied" || operation === "Suministrado" ? (
                  <Link
                    to={editSupply!!}
                    className="text-sm text-default-text flex items-center py-[5px]"
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPencil} />
                    {t("Tree inventory.Edit supply tree")}
                  </Link>
                ) : (
                  <Link
                    to={editDistribute!!}
                    className="text-sm text-default-text flex items-center py-[5px]"
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPencil} />
                    {t("Tree inventory.Edit distribute tree")}
                  </Link>
                )}
              </>

              {propsDelete && (
                <button
                  onClick={() => {
                    isShowAction(false);
                    deleteAction();
                  }}
                  className="text-sm text-default-text flex items-center py-[5px]"
                >
                  <FontAwesomeIcon icon={faTrashCan} className="mr-2" />{" "}
                  {t("PlantingFilter.Delete")}
                </button>
              )}
            </div>
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default InventoryActionItem;
