import { ThemeProvider } from "@emotion/react";
import { ChangeEvent, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { plantingOrganizerFilterSet } from "../../../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import { plantingOrganizerFilterAction } from "../../../store/plantingOrganizerFilter-slice";

type FormValues = {
  isActive: string;
};

const PlantingOrganizerFilterBox = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset, resetField } = useForm<FormValues>();
  const isFilterSet = useSelector(plantingOrganizerFilterSet);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isFilterSet) {
      resetField("isActive");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterSet]);

  const applyFilter = (data: FormValues) => {
    dispatch(
      plantingOrganizerFilterAction.setFilter({
        isActive: data.isActive,
      })
    );
    dispatch(plantingOrganizerFilterAction.setURL());
    dispatch(plantingOrganizerFilterAction.isFilterSet());
  };

  const clearFilter = () => {
    reset();
    dispatch(plantingOrganizerFilterAction.clearFilter());
    dispatch(plantingOrganizerFilterAction.setURL());
  };
  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px]">
                {t("Workshops.Workshop status")}
              </p>

              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, value } }) => (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink={false}>
                      {!value && `${t("Workshops.Workshop status")}`}
                    </InputLabel>
                    <Select
                      style={{ height: "38px" }}
                      IconComponent={ExpandMoreIcon}
                      variant="outlined"
                      value={value || null}
                      onChange={(e) => {
                        onChange(e as ChangeEvent<Element>);
                      }}
                      sx={{
                        border: "1px solid #eeecec",
                        borderRadius: "4px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="true">{t("Department.Active")}</MenuItem>
                      <MenuItem value="false">
                        {t("Department.Inactive")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-ph-input mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}
            <button
              type="submit"
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default PlantingOrganizerFilterBox;
