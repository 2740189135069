import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { registerFilters } from "../../../store";
import { registerFilterAction } from "../../../store/registerFilter-slice";
import AppliedItem from "./AppliedItem";
import AppliedIncidents from "./AppliedIncidents";

const AppliedFilter = () => {
  const filters = useSelector(registerFilters);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
      <h1 className="text-ph-gray text-sm font-medium">
        {t("Tree Species.Applied Filters")}
      </h1>
      <div className="flex flex-wrap items-center">
        {(filters.dateFrom || filters.dateTo) && (
          <AppliedItem
            type={t("Plantings.Date of planting")}
            value={`${filters.dateFrom} ${filters.dateTo !== "" ? "-" : ""} ${
              filters.dateTo
            }`}
            removeFilter={() => {
              dispatch(registerFilterAction.removeDate());
              dispatch(registerFilterAction.setURL());
              dispatch(registerFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.treeSpecies && (
          <AppliedItem
            type={t("Register.Tree species")}
            value={
              i18n.language === "en"
                ? filters.treeSpecies.name_transes.en === ""
                  ? filters.treeSpecies.name_transes.es
                  : filters.treeSpecies.name_transes.en
                : filters.treeSpecies.name_transes.es
            }
            removeFilter={() => {
              dispatch(registerFilterAction.removeTreeSpecies());
              dispatch(registerFilterAction.setURL());
              dispatch(registerFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.collaboratorType && (
          <AppliedItem
            type={t("Register.Responsible category")}
            value={`${
              filters.collaboratorType === "ind" ? t("Register.Individual") : ""
            } ${
              filters.collaboratorType === "ins"
                ? t("Register.Institution")
                : ""
            }  ${filters.collaboratorType === "grp" ? t("Register.Group") : ""} 
            ${filters.collaboratorType === "fun" ? "FUNDAEC" : ""}`}
            removeFilter={() => {
              dispatch(registerFilterAction.removeCollaboratorType());
              dispatch(registerFilterAction.setURL());
              dispatch(registerFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.collaborator && (
          <AppliedItem
            type={t("Register.Responsible type")}
            value={filters.collaborator.name}
            removeFilter={() => {
              dispatch(registerFilterAction.removeCollaboratorName());
              dispatch(registerFilterAction.setURL());
              dispatch(registerFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.incidents.length > 0 && (
          <AppliedIncidents
            type={t("Incidents.Incidents")}
            value={filters.incidents}
            removeFilter={() => {
              dispatch(registerFilterAction.removeIncidents());
              dispatch(registerFilterAction.setURL());
              dispatch(registerFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.other.length > 0 && (
          <AppliedIncidents
            type={t("Register.Other")}
            value={filters.other}
            removeFilter={() => {
              dispatch(registerFilterAction.removeOther());
              dispatch(registerFilterAction.setURL());
              dispatch(registerFilterAction.isFilterSet());
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AppliedFilter;
