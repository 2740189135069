import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PlantingStatistics from "./PlantingStatistics/PlantingStatistics";
import useAxios from "../../hook/useAxios";
import WorkshopStatistics from "./WorkshopStatistics/WorkshopStatistics";
import RegisterStatistics from "./RegisterStatistics/RegisterStatistics";
import { MenuItem, Select, selectClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ThemeProvider } from "@emotion/react";
import { Chart, registerables as registerable } from "chart.js";
import Loading from "../Global/Loading";
import VideoModal from "../HelpVideos/listVideos/VideoModal";
import { colors } from "../../styles/colors";
Chart.register(...registerable);

const Statistics = () => {
  const [years, setYears] = useState<string[]>([]);
  const [selectYear, setSelectYear] = useState<string>(" ");
  const [showDead, setShowDead] = useState(false);

  const { t } = useTranslation();

  const { response: yearsResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/statistics/year/ddl/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: PlantingStatisticsResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/statistics/planting/?year=${selectYear}`,
    "GET",
    true,
    "",
    true,
    false
  );

  const { response: workshopStatisticsResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/statistics/workshop/?year=${selectYear}`,
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: registerStatisticsResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/statistics/register/?year=${selectYear}`,
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (yearsResponse) {
      setYears(yearsResponse.data.year);
    }
  }, [yearsResponse]);
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: colors.light_gray,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "14px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: colors.background_white,
          },
          notchedOutline: {
            borderColor: colors.gray_white,
          },
        },
      },
    },
  });

  return (
    <div className="parent-container">
      <div className="flex justify-between">
        <h1 className="font-semibold text-default-text mt-5">
          {t("Statistics.Annual Statistics")}
        </h1>
        <ThemeProvider theme={theme}>
          <div className="flex flex-col-reverse items-end md:flex-row md:justify-between md:items-center">
            <VideoModal
              needBorder={window.innerWidth >= 700 ? true : false}
              pageName="st"
            />
            <label className="flex items-center mx-2 my-2">
              <input
                type="checkbox"
                onChange={() => setShowDead(!showDead)}
                className="form-checkbox h-4 w-4 accent-ph-btn border-2 border-white text-white"
              />
              <span
                className={`${
                  window.innerWidth >= 700
                    ? "md:border-r-[1px] md:solid md:border-ph-iborder md:pr-4"
                    : ""
                }  text-default-text text-[14px] mx-2 `}
              >
                {t("Tree Species.Show dead trees")}
              </span>
            </label>
            <Select
              style={{
                height: "40px",
                width: "176px",
                backgroundColor: "white",
                margin: "8px 0",
              }}
              IconComponent={CalendarMonthIcon}
              variant="outlined"
              value={selectYear}
              onChange={(e) => {
                setSelectYear(e.target.value);
              }}
              sx={{
                [`& .${selectClasses.iconOpen}`]: {
                  transform: "none",
                },
                border: "1px solid #eeecec",
                borderRadius: "4px",
                "& fieldset": { border: "none" },
              }}
            >
              <MenuItem value=" ">{t("Statistics.All")}</MenuItem>
              {years.map((year: string) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </div>
        </ThemeProvider>
      </div>

      <hr className="mt-4" />
      {PlantingStatisticsResponse &&
      workshopStatisticsResponse &&
      registerStatisticsResponse ? (
        <div className="flex flex-wrap justify-between mt-4 ">
          <div className=" w-full h-fit lg:w-[49%]  rounded-md ">
            <PlantingStatistics
              response={PlantingStatisticsResponse}
              showDead={showDead}
            />
          </div>
          <div className="w-full h-fit lg:w-[49%]  rounded-md mt-4 lg:mt-0">
            <WorkshopStatistics response={workshopStatisticsResponse} />
          </div>
          <div className="w-full h-fit lg:w-[49%]  rounded-md mt-5">
            <RegisterStatistics
              response={registerStatisticsResponse}
              showDead={showDead}
            />
          </div>
        </div>
      ) : (
        <Loading loading={true} />
      )}
    </div>
  );
};

export default Statistics;
