import { Controller } from "react-hook-form";
import {
  Checkbox,
  TextField,
  Autocomplete,
  autocompleteClasses,
} from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { colors } from "../../styles/colors";

interface ReusableAutocompleteProps<T> {
  control: any;
  rules?: any;
  name: string;
  selectOptions: T[];
  placeholder: string;
  disabled?: boolean;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  getOptionLabel: (option: T) => string;
  renderOption?: (
    props: any,
    option: T,
    params: { selected: boolean }
  ) => JSX.Element;
  onCustomChange?: (newValue: T | T[] | null) => void;
  isMulti?: boolean;
  defaultValue?: T | T[];
  readonly?: boolean;
  incident?: boolean;
}

function CustomAutocomplete<T>({
  control,
  rules,
  name,
  selectOptions,
  placeholder,
  disabled = false,
  icon = <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />,
  checkedIcon = (
    <CheckBoxIcon sx={{ color: colors.green_checkbox }} fontSize="small" />
  ),
  getOptionLabel,
  renderOption,
  onCustomChange,
  isMulti = false,
  defaultValue,
  readonly = false,
  incident = false,
}: ReusableAutocompleteProps<T>) {
  const handleChange = (newValue: T | T[] | null) => {
    if (Array.isArray(newValue)) {
      const deduplicated = Array.from(
        new Set(newValue.map((item) => getOptionLabel(item)))
      ).map((label) => newValue.find((item) => getOptionLabel(item) === label));
      onCustomChange && onCustomChange(deduplicated as T[]);
    } else {
      onCustomChange && onCustomChange(newValue);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          popupIcon={icon}
          multiple={isMulti}
          disabled={disabled}
          limitTags={1}
          id="reusable-autocomplete"
          options={selectOptions}
          disableCloseOnSelect={isMulti}
          getOptionLabel={getOptionLabel}
          sx={{
            width: "100%",
            [`& .${autocompleteClasses.popupIndicator}`]: {
              transform: "none",
              color: disabled && readonly && colors.bg_gray,
            },
            color: readonly ? colors.white : undefined,
            border: "1px solid #eeecec",
            borderRadius: "4px",
            "& fieldset": {
              border: "none",
            },
            "& .Mui-focused": {
              outline: "1px solid #efbf07",
            },
            "& .MuiChip-label": {
              color: readonly ? colors.white : undefined,
            },
            "& .MuiFormControl-root": {
              "& .Mui-disabled": {
                backgroundColor:
                  disabled && readonly ? colors.bg_gray : undefined,
                opacity: 100,
              },
            },
            "& .MuiInputBase-root": {
              color: readonly ? colors.white : colors.default_text,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={isMulti && value?.length > 0 ? "" : placeholder}
              InputProps={{
                ...params.InputProps,
                style: {
                  color: readonly ? colors.white : undefined,
                },
              }}
              InputLabelProps={{
                style: {
                  color: readonly ? colors.white : undefined,
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => {
            const isSelected = isMulti
              ? (value || []).some(
                  (v: T) => getOptionLabel(v) === getOptionLabel(option)
                )
              : getOptionLabel(value || {}) === getOptionLabel(option);

            return (
              <li {...props}>
                {isMulti && (
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={checkedIcon}
                    style={{
                      marginRight: 8,
                    }}
                    checked={isSelected}
                  />
                )}
                {getOptionLabel(option)}
              </li>
            );
          }}
          onChange={(event, newValue) => {
            const deduplicatedValue = Array.isArray(newValue)
              ? Array.from(
                  new Set(newValue.map((item) => getOptionLabel(item)))
                ).map((label) =>
                  newValue.find((item) => getOptionLabel(item) === label)
                )
              : newValue;
            onChange(deduplicatedValue);
            handleChange(deduplicatedValue as T | T[] | null);
          }}
          value={value || (isMulti ? value || [] : value || null)}
          readOnly={readonly}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
