import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import TreeInventoryTrackingTableItem from "./TreeInventoryTrackingTableItem";
import GenericTable from "../../Global/GenericTable";
import useAxios from "../../../hook/useAxios";
import { CommunityRecord } from "../../../type/communityRecord";
import i18n from "../../../i18n";

const TreeInventoryTracking = () => {
  const [search, setSearch] = useState<string>("");
  const [nurseryList, setNurseryList] = useState<CommunityRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();

  const { slug } = useParams<any>();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${slug}/inventory_tracking/?&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: breadcrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${slug}/inventory_tracking/breadcrumb_data`,
    "GET",
    true,
    "",
    false,
    false
  );
  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setNurseryList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between flex-col md:flex-row items-start md:items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Tree inventory.Tree inventory tracking")}
        </h1>
        <div className="flex flex-row my-4 md:my-0">
          <span className="md:mr-[16px] md:border-r-[1px] md:solid md:border-ph-iborder pr-4 ">
            <Link
              to={`/setting/nurseries/inventory-tracking/add-distribution/${slug}`}
              className="white-btn px-[23px] py-[10px] border border-ph-btn text-sm font-medium "
            >
              {t("Tree inventory.Distribute trees")}
            </Link>
          </span>

          <span className="">
            <Link
              to={`/setting/nurseries/tree-supplies/add-supply/${slug}`}
              className="green-btn px-[23px] py-[10px] text-sm"
            >
              {t("Tree inventory.Supply trees")}
            </Link>
          </span>
        </div>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          {
            name: `${
              i18n.language === "en"
              ? breadcrumb?.data.name_transes.en === ""
                ? breadcrumb?.data.name_transes.es
                : breadcrumb?.data.name_transes.en
              : breadcrumb?.data.name_transes.es
            }(${breadcrumb?.data.code})`,
            url: "",
          },
          { name: t("Tree inventory.Tree inventory tracking"), url: "" },
        ]}
      />

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />

      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          dataList={nurseryList}
          handleChange={handleChange}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Tree inventory.Order ID"), value: "order_id" },
            { name: t("Tree inventory.Date"), value: "date" },
            { name: t("Plantings.Species"), value: "species" },
            { name: t("Register.Age"), value: "age" },
            {
              name: t("Tree inventory.Nr.trees"),
              value: "no_trees",
            },
            {
              name: t("Tree inventory.Operation"),
              value: "operation",
            },
          ]}
          renderItem={(nurseries, fetchData, lastIndex) => (
            <TreeInventoryTrackingTableItem
              inventories={nurseries}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
    </div>
  );
};

export default TreeInventoryTracking;
