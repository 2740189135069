import { ThemeProvider } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { plantingTheme } from "../Register/RegisterList/PlantingTheme";
import useAxios from "../../hook/useAxios";
import PlantingList from "../../type/PlantingList";
import CustomAutocomplete from "../Global/CustomAutocomplete";
import TranslationField from "../../type/translationField";
import { useDispatch, useSelector } from "react-redux";
import { importFilterDelete, importFilterSet } from "../../store";
import { importFilterAction } from "../../store/importFilter-slice";
import { useHistory } from "react-router-dom";

type FormValues = {
  planting: {
    name_transes: TranslationField;
    slug: string;
    all_dates: string;
    date: string;
    is_register_completed: boolean;
    community_initials: string;
    community_slug: string;
  };
};

const ImportFilterBox: React.FC<{
  applyPlantingFilter: (slug: string) => void;
  plantingSlug?: string;
}> = ({ applyPlantingFilter, plantingSlug }) => {
  const [eventData, setEventData] = useState<PlantingList[]>([]);
  const { t, i18n } = useTranslation();
  const { handleSubmit, control, reset, resetField, setValue } =
    useForm<FormValues>();
  const isFilterApplied = useSelector(importFilterSet);
  const deleteItem = useSelector(importFilterDelete);
  const history = useHistory();

  const { response: plantingAllRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/plantings/all/",
    "GET",
    true,
    "",
    true,
    false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteItem === "planting") {
      resetField("planting");
    }
  }, [deleteItem, resetField]);

  useEffect(() => {
    if (plantingAllRes) {
      const allOption = {
        name_transes: {
          en: "All",
          es: "Todos",
        },
        slug: "",
        all_dates: "",
        date: "",
        is_register_completed: false,
        community_initials: "",
        community_slug: "",
      };
      setEventData([allOption, ...plantingAllRes.data]);
      if (plantingSlug) {
        const selectedPlanting = plantingAllRes.data.find(
          (item: any) => item.slug === plantingSlug
        );
        setValue("planting", selectedPlanting);
        applyFilter({ planting: selectedPlanting });
        dispatch(importFilterAction.setFilter({ planting: selectedPlanting }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantingAllRes, plantingSlug]);

  useEffect(() => {
    if (!isFilterApplied) {
      reset();
      dispatch(importFilterAction.clearFilter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterApplied]);

  const applyFilter = (data: FormValues) => {
    applyPlantingFilter(data.planting.slug);
    dispatch(
      importFilterAction.setFilter({
        planting: data?.planting || null,
      })
    );
    dispatch(importFilterAction.isFilterApplied());
  };

  const clearFilter = () => {
    reset();
    dispatch(importFilterAction.clearFilter());
    history.replace(`/tools/imports`);
  };

  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px]">
                {t("Help videos.Planting event")}
              </p>

              <CustomAutocomplete
                control={control}
                name="planting"
                selectOptions={eventData}
                placeholder={t("Help videos.Planting event")}
                getOptionLabel={(option) =>
                  i18n.language === "en"
                    ? option.name_transes?.en === ""
                      ? option.date
                        ? option.name_transes?.es + " - " + option.date
                        : option.name_transes?.es
                      : option.date
                      ? option.name_transes?.en + " - " + option.date
                      : option.name_transes?.en
                    : option.date
                    ? option.name_transes?.es + " - " + option.date
                    : option.name_transes?.es
                }
              />
            </div>
          </div>
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterApplied && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-ph-input mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}
            <button
              type="submit"
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default ImportFilterBox;
