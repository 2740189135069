import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../../Global/BreadCrumb";
import DistributeTreeForm from "./DistributeTreeForm";
import { useParams } from "react-router-dom";
import useAxios from "../../../../../hook/useAxios";
import i18n from "../../../../../i18n";

const DistributeTrees: React.FC = () => {
  const { t } = useTranslation();
  const { slug } = useParams<any>();

  const { response: breadcrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${slug}/inventory_tracking/breadcrumb_data`,
    "GET",
    true,
    "",
    true,
    false
  );

  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${slug}/distribute/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree inventory.Distribute trees")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          {
            name: `${
              i18n.language === "en"
                ? breadcrumb?.data.name_transes.en === ""
                  ? breadcrumb?.data.name_transes.es
                  : breadcrumb?.data.name_transes.en
                : breadcrumb?.data.name_transes.es
            }(${breadcrumb?.data.code})`,
            url: "",
          },
          {
            name: t("Tree nurseries.Tree inventory tracking"),
            url: `/setting/nurseries/inventory-tracking/${slug}`,
          },
          {
            name: t("Tree inventory.Distribute trees"),
            url: "",
          },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <DistributeTreeForm
            nurseryCode={breadcrumb?.data.code}
            send={fetchData}
            sendResponse={response}
            id={slug}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add growth stage.svg"
            alt="addEvent"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default DistributeTrees;
