import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../Global/BreadCrumb";
import { ImportRegisterRecord } from "../../type/importRegister";
import useAxios from "../../hook/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowsRotate,
} from "@fortawesome/pro-regular-svg-icons";
import GenericTable from "../Global/GenericTable";
import AppliedItem from "../Register/RegisterList/AppliedItem";
import DownloadTemplateImport from "./DownloadTemplateImport";
import ImportFilterBox from "./ImportFilterBox";
import ImportTableItem from "./ImportTableItem";
import { useHistory, useParams } from "react-router-dom";
import { importFilters, importFilterSet } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { importFilterAction } from "../../store/importFilter-slice";
import ImportImage from "./ImportImages/ImportImage";
import ImportRegister from "./ImportRegister/ImportRegister";

const ImportList = () => {
  const isFilterApplied = useSelector(importFilterSet);
  const filters = useSelector(importFilters);
  const [importList, setImportList] = useState<ImportRegisterRecord[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [rotate, setRotate] = useState(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [downloadTemplateImport, isDownloadTemplateImport] =
    useState<boolean>(false);
  const [showButton, isShowButton] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [slug, setSlug] = useState<string>(filters?.planting?.slug || "");
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showImportImageModal, setShowImportImageModal] =
    useState<boolean>(false);
  const [showImportRegisterModal, setShowImportRegisterModal] =
    useState<boolean>(false);
  const [selectedPlantingSlug, setSelectedPlantingSlug] = useState<
    string | null
  >(null);

  const {
    fetchData: getImport,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/imports${`/${slug}`}?page=${page}&ordering=${ordering}`,
    "GET",
    false,
    "",
    true,
    true,
    "application/json",
    timeZone
  );

  useEffect(() => {
    if (id) {
      setSlug(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (loading && importList.length > 0) {
      setIsLoaded(false);
    } else if (!isFilterApplied) {
      setIsLoaded(false);
    } else {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (isFilterApplied) {
      getImport({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, ordering, id, isFilterApplied, slug, selectedPlantingSlug]);

  useEffect(() => {
    if (isFilterApplied && response !== null) {
      setCount(response.data.count);
      setImportList(response.data.results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const refresh = () => {
    getImport({});
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 2000);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowButton(false);
    }
  };

  const applyPlantingFilter = (slug: string) => {
    setSlug(slug);
  };

  const resetFilter = () => {
    setSlug("");
    dispatch(importFilterAction.removePlanting());
    dispatch(importFilterAction.isFilterApplied());
    history.replace(`/tools/imports`);
  };

  useEffect(() => {
    if (!id && !selectedPlantingSlug) {
      resetFilter();
    }
  }, [id, selectedPlantingSlug]);

  const handleShowImportImageModal = () => {
    setShowImportImageModal(true);
  };

  const closeImportImageModal = () => {
    setShowImportImageModal(false);
  };

  const handleImageImportSuccess = (selectedSlug: string | null) => {
    setShowImportImageModal(false);
    if (selectedSlug) {
      setSelectedPlantingSlug(selectedSlug);
      applyPlantingFilter(selectedSlug);
    }
    refresh();
  };

  const handleShowImportRegisterModal = () => {
    setShowImportRegisterModal(true);
  };

  const closeImportRegisterModal = () => {
    setShowImportRegisterModal(false);
  };

  const handleRegisterImportSuccess = (selectedSlug: string | null) => {
    setShowImportRegisterModal(false);
    if (selectedSlug) {
      setSelectedPlantingSlug(selectedSlug);
      applyPlantingFilter(selectedSlug);
    }
    refresh();
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center mt-5 flex-wrap gap-2 md:gap-0">
        <div className="mt-8 sm:mt-0">
          <h1 className="font-semibold text-default-text text-[16px] inline-block mr-5">
            {t("Import register.Imports")}
          </h1>
          <button type="button" onClick={refresh} className="text-default-text">
            <FontAwesomeIcon
              className={`${
                rotate ? "animate-spin" : ""
              } duration-1000 ease-linear`}
              icon={faArrowsRotate}
            />
          </button>
        </div>
        <div>
          <div className="flex flex-wrap relative mt-5 text-sm">
            <button
              onClick={() => {
                isDownloadTemplateImport(true);
              }}
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6 mr-4 mt-4 sm:mt-0"
            >
              {t("Import register.Download register template")}
            </button>

            <span className="hidden sm:inline-block border-l-[1px] text-ph-iborder mr-4 align-middle h-[37px]" />

            <button
              ref={btnRef}
              className="green-btn pl-[23px] pr-4 py-2 text-sm mt-4 sm:mt-0"
              type="button"
              onClick={() => {
                isShowButton(!showButton);
              }}
            >
              {t("Import register.Import")}
              <span className="border-l-[1px] solid text-ph-iborder ml-4 mr-2 h-[30px] my-[-15px] align-middle inline-block" />
              <FontAwesomeIcon
                className="text-base"
                icon={showButton ? faAngleUp : faAngleDown}
              />
            </button>

            {showButton && (
              <div
                ref={divRef}
                className="absolute top-10 right-0 w-[226px] text-black p-4 shadow-xl rounded-md bg-white text-left z-10"
              >
                <button
                  type="button"
                  onClick={handleShowImportRegisterModal}
                  className="hover:text-ph-hover pb-2 font-medium"
                >
                  {t("Import register.Import registers")}
                </button>
                <button
                  type="button"
                  onClick={handleShowImportImageModal}
                  className="hover:text-ph-hover pt-2 text-left font-medium"
                >
                  {t("Import register.Import images")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Import register.Tools"), url: "" },
          {
            name: t("Import register.Imports"),
            url: "",
          },
        ]}
      />
      <ImportFilterBox
        applyPlantingFilter={(slug: string) => {
          applyPlantingFilter(slug);
        }}
        plantingSlug={selectedPlantingSlug !== null ? selectedPlantingSlug : id}
      />
      {isFilterApplied ? (
        <div>
          <div className="bg-white p-4 rounded-md relative mt-3">
            <h1 className="text-ph-gray font-medium text-sm">
              {t("Tree Species.Applied Filters")}
            </h1>
            <div className="flex flex-wrap items-center">
              <AppliedItem
                type={t("Help videos.Planting event")}
                value={
                  (i18n.language === "en"
                    ? filters?.planting?.name_transes?.en === ""
                      ? filters?.planting?.date
                        ? filters?.planting?.name_transes?.es +
                          " - " +
                          filters?.planting?.date
                        : filters?.planting?.name_transes?.es
                      : filters?.planting?.date
                      ? filters?.planting?.name_transes?.en +
                        " - " +
                        filters?.planting?.date
                      : filters?.planting?.name_transes?.en
                    : filters?.planting?.date
                    ? filters?.planting?.name_transes?.es +
                      " - " +
                      filters?.planting?.date
                    : filters?.planting?.name_transes?.es) || ""
                }
                removeFilter={resetFilter}
              />
            </div>
          </div>
          <div className="p-4 bg-white mt-3 rounded-md">
            <GenericTable
              countData={count}
              handleChange={handleChange}
              dataList={importList}
              fetchData={() => {
                getImport({});
              }}
              loading={loading}
              setOrderingList={order}
              titles={[
                { name: t("Import register.Event name"), value: "name" },
                {
                  name: t("Import register.Event date"),
                  value: "date",
                },
                {
                  name: t("Import register.Import file"),
                  value: "",
                },
                { name: t("Import register.File size"), value: "file_size" },
                {
                  name: t("Import register.End time"),
                  value: "completion_time",
                },
                { name: t("Import register.Imported/Total"), value: "" },
                { name: t("Workshops.Workshop status"), value: "status" },
                {
                  name: t("Import register.Unimported register log"),
                  value: "",
                },
              ]}
              renderItem={(importRecord, fetchData) => (
                <ImportTableItem
                  importItem={importRecord}
                  fetchData={() => {}}
                />
              )}
              showAction={false}
            />
            <br />
          </div>
        </div>
      ) : (
        <div className="p-4 bg-white mt-3 rounded-md text-center text-default-text">
          <div>
            <p className="md:mt-28 mr-4 font-medium text-2xl">
              {t("Import register.No data")}
            </p>
            <p className="md:w-[75%] md:pl-[20%] mt-4 font-medium text-base ">
              {t("Import register.Select event filter")}
            </p>
          </div>
          <img
            src="/img/Imports illu.png"
            alt="addEvent"
            className="mx-auto mt-8 md:mt-[-90px] md:ml-2 md:mr-5"
          />
        </div>
      )}
      {showImportImageModal && (
        <ImportImage
          handleModalClose={closeImportImageModal}
          onImageImportSuccess={handleImageImportSuccess}
          plantingId={id ? id : ""}
        />
      )}
      {showImportRegisterModal && (
        <ImportRegister
          handleModalClose={closeImportRegisterModal}
          onRegisterImportSuccess={handleRegisterImportSuccess}
          plantingId={id ? id : ""}
        />
      )}
      {downloadTemplateImport && (
        <DownloadTemplateImport
          closePopOver={() => {
            isDownloadTemplateImport(false);
          }}
        />
      )}
    </div>
  );
};

export default ImportList;
