import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Warning from "../../ErrorAndAlert/Warning";
import { theme } from "../../Register/AddRegister/FormTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import zxcvbn from "zxcvbn";
import PasswordStrength from "../../Forgot Password/PasswordStrength";
import SelectLanguage from "../../Global/SelectLanguage";
import Status from "../../Global/Status";

type password = {
  showText: boolean;
  text: string;
  strength: number;
};
type FormValues = {
  fName: string;
  lName: string;
  phone: string;
  address: string;
  email: string;
  role: string;
};

const AddUserForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = ({ send, sendResponse, getInfo, textButton }) => {
  const [password, setPassword] = useState<password>({
    showText: false,
    text: "",
    strength: -1,
  });
  const [confirmPassword, setConfirmPassword] = useState<{
    text: string;
    showText: boolean;
  }>({
    showText: false,
    text: "",
  });
  const [disable, isDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const [showError, isShowError] = useState<boolean>(false);
  const [lang, setLang] = useState<string>("ES");
  const history = useHistory();

  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${
          getInfo
            ? `${t("Message.The record updated successfully")}`
            : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace("/setting/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setLoading(true);
      isDisable(false);
      setValue("fName", getInfo.data.first_name);
      setValue("lName", getInfo.data.last_name);
      setValue("email", getInfo.data.email);
      setValue("phone", getInfo.data.phone);
      setValue("address", getInfo.data.address);
      setValue("role", getInfo.data.role);
      setLang(getInfo.data.language === "en" ? "EN" : "ES");
      isActive(getInfo.data.is_active);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let score = 0;
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        event.target.value
      )
    ) {
      score = 5;
    } else {
      score = zxcvbn(event.target.value).score;
    }
    setPassword((prevState) => {
      return {
        ...prevState,
        text: event.target.value,
        strength: score,
      };
    });
    if (score === 5 && event.target.value === confirmPassword.text) {
      isDisable(false);
    } else {
      isDisable(true);
    }
  };

  const confChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword({
      showText: confirmPassword.showText,
      text: event.target.value,
    });
    if (password.strength === 5 && event.target.value === password.text) {
      isDisable(false);
    } else {
      isDisable(true);
    }
  };

  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  const addUser = (data: FormValues) => {
    if (!disable || (getInfo && !password.text)) {
      isDirty(false);
      if (getInfo && !password.text) {
        send({
          first_name: data.fName,
          last_name: data.lName,
          email: data.email,
          phone: data.phone,
          address: data.address,
          role: data.role,
          language: lang === "EN" ? "en" : "es",
          is_active: active,
        });
      } else {
        send({
          first_name: data.fName,
          last_name: data.lName,
          email: data.email,
          password: password.text,
          confirm_password: confirmPassword.text,
          phone: data.phone,
          address: data.address,
          role: data.role,
          language: lang === "EN" ? "en" : "es",
          is_active: active,
        });
      }
    }
  };
  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addUser)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-ph-dark-text mb-[6px]">
              {t("Users.Preferred language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-ph-dark-text mb-[6px]">
                  {t("Users.First name")} *
                </p>
                <input
                  {...register("fName", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="text"
                  placeholder={t("Users.First name") + ""}
                  className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
                />
                {errors.fName && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-full sm:w-1/2 sm:pl-2">
                <p className="text-sm text-ph-dark-text mb-[6px] mt-7 sm:mt-0">
                  {t("Users.Last name")} *
                </p>
                <input
                  {...register("lName", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="text"
                  className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
                  placeholder={t("Users.Last name") + ""}
                />
                {errors.lName && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-7 items-center">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-ph-dark-text mb-[6px]">
                  {t("Users.Role")} *
                </p>
                <Controller
                  control={control}
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && `${t("Users.Role")}`}
                      </InputLabel>
                      <Select
                        style={{ height: "48px" }}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || null}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                          if (e.target.value === "A") {
                            isActive(true);
                          }
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="A">{t("Users.Admin")}</MenuItem>
                        <MenuItem value="C">{t("Users.Coordinator")}</MenuItem>
                        <MenuItem value="O">
                          {t("Organizer.Collaborator")}
                        </MenuItem>
                        <MenuItem value="V">{t("Users.Visitor")}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.role && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 flex">
                <Status
                  active={active}
                  isActive={(value) => {
                    isActive(value);
                  }}
                />
              </div>
            </div>

            <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
              {t("Users.Phone")}
            </p>
            <input
              {...register("phone")}
              type="text"
              className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-ph-light-gray"
              placeholder={t("Users.Phone") + ""}
            />

            <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
              {t("Users.Address")}
            </p>
            <input
              {...register("address")}
              type="text"
              className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-ph-light-gray"
              placeholder={t("Users.Address") + ""}
            />

            <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
              {t("Users.Email")} *
            </p>
            <input
              {...register("email", {
                required: `${t("Message.Required field")}`,
              })}
              type="text"
              className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-ph-light-gray"
              placeholder={t("Users.Email") + ""}
              autoComplete="new-password"
            />
            {errors.email && (
              <p className="error-text">{t("Message.Required field")}</p>
            )}

            <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
              {t("Login.Password")} *
            </p>
            <div className="relative">
              <input
                onChange={passwordChange}
                value={password?.text}
                type={password.showText ? "text" : "password"}
                placeholder={getInfo ? "********" : t("Login.Password") + ""}
                className="input-field text-sm py-3 pl-6 placeholder-ph-light-gray"
                autoComplete="new-password"
              />
              <span
                onClick={() => {
                  setPassword((prevState) => {
                    return {
                      ...prevState,
                      showText: !prevState.showText,
                    };
                  });
                }}
                className="text-ph-gray absolute right-3 top-3 cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={password.showText ? faEye : faEyeSlash}
                />
              </span>
            </div>
            <PasswordStrength
              strengthNum={password?.strength}
              password={password.text}
            />

            <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
              {t("Users.Password confirmation")} *
            </p>
            <div className="relative">
              <input
                onChange={confChange}
                value={confirmPassword?.text}
                type={confirmPassword.showText ? "text" : "password"}
                placeholder={
                  getInfo ? "********" : t("Users.Password confirmation") + ""
                }
                className="input-field text-sm py-3 pl-6 placeholder-ph-light-gray"
              />
              <span
                onClick={() => {
                  setConfirmPassword((prevState) => {
                    return {
                      ...prevState,
                      showText: !prevState.showText,
                    };
                  });
                }}
                className="text-ph-gray absolute right-3 top-3 cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={confirmPassword.showText ? faEye : faEyeSlash}
                />
              </span>
            </div>
            {showError && password.strength !== 5 && (
              <p className="error-text">
                {t("Login.The password does not match the required criteria.")}
              </p>
            )}
            {showError && password.text !== confirmPassword.text && (
              <p className="error-text">
                {t("Login.The repeated password does not match.")}
              </p>
            )}

            <button
              onClick={() => {
                if (!getInfo || (getInfo && password.text)) {
                  isShowError(true);
                }
              }}
              type="submit"
              className="green-btn px-6 mt-9 py-2 text-sm float-right font-medium"
            >
              {textButton}
            </button>
          </ThemeProvider>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddUserForm;
