import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import UserFilterBox from "./UserFilterBox";
import { useDispatch, useSelector } from "react-redux";
import { userFilterSet, userFilters, userURL } from "../../../store";
import { userFilterAction } from "../../../store/userFilter-slice";
import AppliedItem from "../../Register/RegisterList/AppliedItem";
import SearchField from "../../Global/SearchField";
import Export from "../../ExportLists/Export";
import Loading from "../../Global/Loading";
import useAxios from "../../../hook/useAxios";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import GenericTable from "../../Global/GenericTable";
import { UsersRecord } from "../../../type/user";
import UserTableItem from "./UserTableItem";

const Users = () => {
  const [users, setUsers] = useState<UsersRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const filters = useSelector(userFilters);
  const isFilter = useSelector(userFilterSet);
  const url = useSelector(userURL);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/users${url}&ordering=${ordering}&page=${page}&search=${sendSearch}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setUsers(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  const handleLoading = () => {
    setExportLoading(!exportLoading);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Users.Users")}
        </h1>
        <div>
          <Export
            url={url}
            name="users"
            title={t("Users.Export users") + ""}
            handleLoading={handleLoading}
            needBorder={true}
          />
          <Link
            to="/setting/users/add-user"
            className="green-btn px-[23px] py-[10px] text-sm"
          >
            {t("Users.Add user")}
          </Link>
        </div>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Users.Users"), url: "" },
        ]}
      />

      <UserFilterBox />
      {isFilter && (
        <div className="bg-white p-4 rounded-md relative mt-3">
          <h1 className="text-ph-gray text-sm">
            {t("Tree Species.Applied Filters")}
          </h1>
          <div className="flex flex-wrap items-center">
            {filters.role && (
              <AppliedItem
                type={t("Users.Role")}
                value={
                  filters.role === "A"
                    ? t("Users.Admin")
                    : filters.role === "C"
                    ? t("Users.Coordinator")
                    : filters.role === "O"
                    ? t("Organizer.Collaborator")
                    : t("Users.Visitor")
                }
                removeFilter={() => {
                  dispatch(userFilterAction.removeRole());
                  dispatch(userFilterAction.setURL());
                  dispatch(userFilterAction.isFilterSet());
                }}
              />
            )}
            {filters.isActive && (
              <AppliedItem
                type={t("Workshops.Workshop status")}
                value={
                  filters.isActive === "true"
                    ? t("Department.Active")
                    : t("Department.Inactive")
                }
                removeFilter={() => {
                  dispatch(userFilterAction.removeStatus());
                  dispatch(userFilterAction.setURL());
                  dispatch(userFilterAction.isFilterSet());
                }}
              />
            )}
          </div>
        </div>
      )}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          handleChange={handleChange}
          dataList={users}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          showAction={true}
          titles={[
            { name: t("Tree Species.Name"), value: "first_name" },
            {
              name: t("Users.Email"),
              value: "email",
            },
            { name: t("Users.Role"), value: "role" },
            { name: t("Department.Status"), value: "is_active" },
            { name: t("Users.Last login"), value: "last_login" },
          ]}
          renderItem={(user, fetchData, lastIndex) => (
            <UserTableItem
              user={user}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
      {exportLoading && <Loading loading={true} />}
    </div>
  );
};

export default Users;
