import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import GenericTable from "../../Global/GenericTable";
import useAxios from "../../../hook/useAxios";
import { CommunityRecord } from "../../../type/communityRecord";
import TreeSuppliesTableItem from "./TreeSuppliesTableItem";
import i18n from "../../../i18n";

const TreeSupplies = () => {
  const [suppliesList, setSuppliesList] = useState<CommunityRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();

  const { slug } = useParams<any>();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${slug}/supply/?&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: breadcrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/${slug}/inventory_tracking/breadcrumb_data`,
    "GET",
    true,
    "",
    true,
    false
  );
  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setSuppliesList(response.data.results);
    }
  }, [response]);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Tree nurseries.Tree supplies")}
        </h1>
        <h1 className="font-medium text-default-text text-[14px] mt-5">
          {t("Tree inventory.Date")}: {date}
        </h1>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          {
            name: `${
              i18n.language === "en"
                ? breadcrumb?.data.name_transes.en === ""
                  ? breadcrumb?.data.name_transes.es
                  : breadcrumb?.data.name_transes.en
                : breadcrumb?.data.name_transes.es
            }(${breadcrumb?.data.code})`,
            url: "",
          },
          { name: t("Tree nurseries.Tree supplies"), url: "" },
        ]}
      />

      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          countData={count}
          dataList={suppliesList}
          handleChange={handleChange}
          showAction={false}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Plantings.Species"), value: "species" },
            { name: t("Tree inventory.Current age"), value: "current_age" },
            {
              name: t("Tree nurseries.Available trees"),
              value: "available_trees",
            },
          ]}
          renderItem={(supplies) => (
            <TreeSuppliesTableItem supplies={supplies} />
          )}
        />
        <br />
      </div>
    </div>
  );
};

export default TreeSupplies;
