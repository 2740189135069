/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";
import { ResponsibleRecord } from "../../../type/responsible";
import AddResponsible from "../AddResponsible/AddResponsible";

const ResponsibleTableItem: React.FC<{
  responsibleItem: ResponsibleRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ responsibleItem, fetchData: propFetchData, lastIndex }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/responsible_names/${responsibleItem.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );

  const { fetchData: editResponsible, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/responsible_names/${responsibleItem.slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );
  const { fetchData: getResponsible, response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/responsible_names/${responsibleItem.slug}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (editResponse) {
      propFetchData();
      toast.success(t("Message.The record updated successfully"));
      setShowEditModal(false);
    }
  }, [editResponse]);

  useEffect(() => {
    if (deleteR) {
      propFetchData();
      isShowDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (responsibleItem.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(
        t(
          "Message.You can't delete the responsible. It is used in a register, tag or nursery."
        )
      );
    }
  };

  const editAction = async () => {
    await getResponsible({});
    setShowEditModal(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  const category =
    i18n.language === "en"
      ? responsibleItem.responsible_category_transes.en
      : responsibleItem.responsible_category_transes.es;

  const active =
    i18n.language === "en"
      ? responsibleItem.is_active_transes.en
      : responsibleItem.is_active_transes.es;
  return (
    <Fragment>
      <TableCell align="left">
        {responsibleItem.first_name + " " + responsibleItem.last_name}
      </TableCell>
      <TableCell align="left">{responsibleItem.initials}</TableCell>
      <TableCell align="left">{category}</TableCell>
      <TableCell align="left">{responsibleItem.responsible_type}</TableCell>
      <TableCell>
        <p
          className={`${
            responsibleItem.is_active_transes.en === "Active"
              ? "bg-ph-toggle-bg-green text-ph-toggle-text-green"
              : "bg-ph-toggle-bg-red text-ph-red-warning"
          } text-center w-16 py-1 rounded-xl`}
        >
          {active}
        </p>
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editAction={editAction}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
          isEditModal
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Map.Responsible")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}

      {showEditModal && (
        <AddResponsible
          handleModalClose={handleModalClose}
          send={editResponsible}
          getInfo={getEditResponse}
          textButton={t("Tree Species.Update")}
          title={t("Responsible.Edit responsible")}
        />
      )}
    </Fragment>
  );
};

export default ResponsibleTableItem;
