import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";
import InfoPopoverNursery from "../../TreeNurseries/TreeNurseriesList/InfoPopoverNursery";
import { TreeInventoryRecord } from "../../../type/treeInventoryRecord";
import InventoryActionItem from "./InventoryActionItem";
import { useSelector } from "react-redux";
import { profileInfo } from "../../../store";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { toast } from "react-toastify";

const TreeInventoryTrackingTableItem: React.FC<{
  inventories: TreeInventoryRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ inventories, fetchData: propFetchData, lastIndex }) => {
  const { t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const { slug } = useParams<any>();
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/origins/inventory_tracking/${inventories.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;
  const profile = useSelector(profileInfo);
  const deleteAction = () => {
    if (!inventories.is_deletable) {
      toast.error(
        t(
          "Message.You can't delete the supplied trees. There are distributed trees for selected species and age."
        )
      );
    } else {
      isShowDelete(true);
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  return (
    <Fragment>
      <TableCell align="left">{inventories.order_id || "-"}</TableCell>
      <TableCell align="left" width="190px">
        {inventories.date}
      </TableCell>
      <TableCell align="left">
        {i18n.language === "en"
          ? inventories.species_name_transes?.en === ""
            ? inventories.species_name_transes?.es
            : inventories.species_name_transes?.en
          : inventories.species_name_transes?.es}
      </TableCell>
      <TableCell align="left">{inventories.age}</TableCell>
      <TableCell align="left" width="200px">
        {inventories.no_trees}
      </TableCell>
      <TableCell align="left" width="150px">
        <span className="flex flex-row items-center">
          {i18n.language === "en"
            ? inventories.operation_transes?.en === ""
              ? inventories.operation_transes?.es
              : inventories.operation_transes?.en
            : inventories.operation_transes?.es}{" "}
          {inventories.note_transes.en || inventories.note_transes.es ? (
            <InfoPopoverNursery
              index={index}
              scroll={scroll}
              note={
                i18n.language === "en"
                  ? inventories.note_transes?.en === ""
                    ? inventories.note_transes?.es
                    : inventories.note_transes?.en
                  : inventories.note_transes?.es
              }
            />
          ) : null}{" "}
        </span>
      </TableCell>
      {(profile.role === "A" || profile.role === "C") && (
        <TableCell align="right">
          <InventoryActionItem
            delete={true}
            editLink={`/setting/inventories/edit-inventory/${inventories.slug}`}
            deleteAction={deleteAction}
            editSupply={`/setting/nurseries/tree-supplies/edit-supply/${inventories.slug}/${slug}`}
            editDistribute={`/setting/nurseries/inventory-tracking/edit-distribution/${inventories.slug}/${slug}`}
            operation={
              inventories.operation_transes.en ||
              inventories.operation_transes.es
            }
          />
        </TableCell>
      )}
      {showDelete && (
        <WarningRedAlert
          type={t("Tree inventory.Inventory")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default TreeInventoryTrackingTableItem;
