import { ThemeProvider } from "@emotion/react";
import GenericTable from "../../../../Global/GenericTable";
import TablePagination from "../../../../Global/TablePagination";
import { useEffect, useState } from "react";
import useAxios from "../../../../../hook/useAxios";
import { TableThem } from "../../../../../styles/TableThem";
import { useTranslation } from "react-i18next";
import MunicipalityTableItem from "./MunicipalityTableItem";
import { MunicipalityRecord } from "../../../../../type/municipalityRecord";
import AddMunicipality from "../AddMunicipality/AddMunicipality";
import { toast } from "react-toastify";

const MunicipalityTab = ({ id }: { id: string }) => {
  const [municipalityList, setCommunityList] = useState<MunicipalityRecord[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();
  const {
    fetchData: getMunicipalities,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${id}/municipalities/?&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: addData, response: addResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/municipalities/${id}/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setCommunityList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    if (addResponse) {
      getMunicipalities({});
      toast.success(t("Message.The record added successfully"));
      setShowAddModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleAddMunicipality = () => {
    setShowAddModal(true);
  };

  const handleModalClose = () => {
    setShowAddModal(false);
  };

  return (
    <div className="p-4 bg-white mt-3 rounded-md w-full">
      <div className="flex justify-end mb-11">
        <button
          onClick={handleAddMunicipality}
          className="green-btn px-[23px] py-[10px] text-sm"
        >
          {t("Municipality.Add municipality")}
        </button>
      </div>
      <GenericTable
        countData={count}
        handleChange={handleChange}
        dataList={municipalityList}
        fetchData={() => {
          getMunicipalities({});
        }}
        loading={loading}
        setOrderingList={order}
        titles={[
          { name: t("Community.Municipality"), value: "municipality" },
          { name: t("Department.Status"), value: "is_active" },
        ]}
        renderItem={(municipality, fetchData, lastIndex) => (
          <MunicipalityTableItem
            municipality={municipality}
            fetchData={fetchData}
            lastIndex={lastIndex}
          />
        )}
      />
      <br />
      {showAddModal && (
        <AddMunicipality
          handleModalClose={handleModalClose}
          send={addData}
          textButton={t("Municipality.Add municipality")}
          title={t("Municipality.Add municipality")}
        />
      )}
    </div>
  );
};

export default MunicipalityTab;
