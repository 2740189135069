import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import file from "../../../type/file";
import useAxios from "../../../hook/useAxios";
import DragDropField from "../../Global/DragDropField";
import { Modal, Snackbar, ThemeProvider } from "@mui/material";
import TranslationField from "../../../type/translationField";
import { useForm } from "react-hook-form";
import PlantingList from "../../../type/PlantingList";
import { theme } from "../../Register/AddRegister/FormTheme";
import CustomAutocomplete from "../../Global/CustomAutocomplete";

type FormValues = {
  planting: {
    name_transes: TranslationField;
    slug: string;
    all_dates: string;
    date: string;
    is_register_completed: boolean;
    community_initials: string;
    community_slug: string;
  };
  fileAddress: string;
};
const ImportRegister = ({
  handleModalClose,
  onRegisterImportSuccess,
  plantingId,
}: {
  handleModalClose: () => void;
  onRegisterImportSuccess: (slug: string) => void;
  plantingId: any;
}) => {
  const { t, i18n } = useTranslation();
  const [eventData, setEventData] = useState<PlantingList[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<file[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mandatoryError, isMandatoryError] = useState<boolean>(false);
  const [emptyPlanting, isEmptyPlanting] = useState<boolean>(false);
  const [localSlug, setLocalSlug] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const { control, watch, setValue } = useForm<FormValues>();
  const selectedPlanting = watch("planting");
  const slug = selectedPlanting?.slug || "";

  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${slug}/import/`,
    "POST",
    false,
    `${t(
      "Message.Import process initiated. Keep using the site, and check import results here later."
    )}`,
    true,
    true
  );

  useEffect(() => {
    if (response) {
      setLoading(false);
      handleModalClose();
      setSnackbarOpen(true);
      onRegisterImportSuccess(localSlug!);
    }
  }, [response, handleModalClose, localSlug, onRegisterImportSuccess]);

  useEffect(() => {
    if (selectedPlanting && selectedPlanting.slug) {
      setLocalSlug(selectedPlanting.slug);
      isEmptyPlanting(false);
    }
  }, [selectedPlanting]);

  const { response: plantingAllRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/plantings/all/",
    "GET",
    true,
    "",
    true,
    false
  );
  useEffect(() => {
    if (plantingAllRes) {
      setEventData([...plantingAllRes.data]);
      if (plantingId) {
        const selectedPlanting = plantingAllRes.data.find(
          (item: any) => item.slug === plantingId
        );
        setValue("planting", selectedPlanting);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantingAllRes, plantingId]);

  const insertFile = (newFile: file[]) => {
    if (!newFile[0].type.includes("text/csv")) {
      toast.error(`${t("Message.The supported formats is CSV")}`);
    } else if (newFile[0].size > 3000000) {
      toast.error(`${t("Message.Maximum file size is 3 MB")}`);
    } else {
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFile]);
      isMandatoryError(false);
    }
  };

  const removeFile = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const onImport = () => {
    isEmptyPlanting(false);
    isMandatoryError(false);

    let hasError = false;

    if (!localSlug) {
      isEmptyPlanting(true);
      hasError = true;
    }

    if (selectedFiles.length === 0) {
      isMandatoryError(true);
      hasError = true;
    }
    if (!hasError) {
      const formData = new FormData();
      formData.append("slug", localSlug!!);
      formData.append("file_address", selectedFiles[0].data);
      setLoading(true);
      fetchData(formData);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Modal
      open
      onClose={() => !loading && handleModalClose()}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="py-12 px-8 bg-ph-white w-[90%] max-h-[90vh] md:w-[65%] lg:w-[35%] overflow-auto">
        <div className="font-semibold text-default-text text-base">
          {t("Import register.Import registers")}
        </div>

        <ThemeProvider theme={theme}>
          <div className="flex flex-wrap justify-between w-full mt-8">
            <div className="w-full">
              <p className="text-sm mb-[6px]">
                {t("Help videos.Planting event")} *
              </p>

              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                }}
                control={control}
                name="planting"
                selectOptions={eventData}
                placeholder={t("Help videos.Planting event")}
                getOptionLabel={(option) =>
                  i18n.language === "en"
                    ? option.name_transes?.en === ""
                      ? option.date
                        ? option.name_transes?.es + " - " + option.date
                        : option.name_transes?.es
                      : option.date
                      ? option.name_transes?.en + " - " + option.date
                      : option.name_transes?.en
                    : option.date
                    ? option.name_transes?.es + " - " + option.date
                    : option.name_transes?.es
                }
              />
              {emptyPlanting && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
          </div>
          <div>
            <p className="text-sm text-default-text mt-7 mb-[6px]">
              {t("Import register.Registers CSV file")} *
            </p>
            <DragDropField
              files={selectedFiles}
              format="csv (max 3 MB)"
              insertFile={insertFile}
              removeFile={removeFile}
              mandatoryError={mandatoryError}
            />
          </div>

          <div className="mt-5 flex flex-row justify-end">
            <button
              onClick={() => {
                !loading && handleModalClose();
              }}
              type="button"
              className="text-default-text text-sm mt-16rounded-3xl px-6 py-2 mt-5 font-medium"
              disabled={loading}
            >
              {t("Message.Cancel")}
            </button>
            <button
              onClick={onImport}
              type="button"
              className="green-btn text-sm px-6 py-2 mt-5 float-right font-medium"
              disabled={loading}
            >
              {t("Import")}
            </button>
          </div>
        </ThemeProvider>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={t(
            "Import started. Keep using the site. Check results later. Refresh for updates."
          )}
        />
      </div>
    </Modal>
  );
};

export default ImportRegister;
