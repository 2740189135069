import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { selectLangTheme } from "../../Global/SelectLanguage";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import { Community } from "../../../type/community";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResponsibleNames } from "../../../type/responsibleNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import LocationPage from "../../Register/GetLocation/LocationPage";
import GeneralAlert from "../../ErrorAndAlert/GeneralAlert";
import { NurseryResponsible } from "../../../type/nurseryResponsible";
import NumberField from "../../Global/NumberField";
import { isSupportedFormat } from "../../Global/supportedFormat.";

type FormValues = {
  code: string;
  name_transes: TranslationField;
  type: "in" | "ex";
  capacity: number;
  community: Community;
  responsible_name: NurseryResponsible;
  latitude: number;
  longitude: number;
  description_transes: TranslationField;
  phone: string;
  total_trees: number;
};

const AddTreeNurseriesForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = ({ send, sendResponse, getInfo, textButton }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("ES");
  const [translatableInput, setTranslatableInput] = useState<{
    name: TranslationField;
    description: TranslationField;
  }>({
    name: { en: "", es: "" },
    description: { en: "", es: "" },
  });
  const [resources, setResources] = useState<file[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [uploading, isUploading] = useState<boolean>(false);
  const [locationView, isLocationView] = useState<boolean>(false);
  const [alert, isAlert] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [communities, setCommunities] = useState<{
    community: Community[];
  }>({ community: [] });
  const [responsible, setResponsible] = useState<{
    responsible: ResponsibleNames[];
  }>({ responsible: [] });
  const [oldResource, setOldResource] = useState<
    {
      id: number;
      file_address: string;
    }[]
  >([]);
  const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
  const [requestNumber, setRequestNumber] = useState<number>(1);
  const [dirty, isDirty] = useState<boolean>(true);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    register,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const resValue = watch("responsible_name");

  const { fetchData: sendFile, response: fileResp } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/files/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );
  const { fetchData: deleteImage } = useAxios(
    "",
    "DELETE",
    false,
    "",
    true,
    true
  );
  const { response: communityRes } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/communities/all/`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: responsibleRes } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/responsible_names/all/`,
    "GET",
    true,
    "",
    false,
    false
  );

  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  //getting community response
  useEffect(() => {
    if (communityRes) {
      if (communityRes.data) {
        setCommunities((prevState) => ({
          ...prevState,
          community: communityRes.data,
        }));
      }
    }
    if (responsibleRes) {
      if (responsibleRes.data) {
        setResponsible((prevState) => ({
          ...prevState,
          responsible: responsibleRes.data,
        }));
      }
    }
  }, [communityRes, responsibleRes]);

  useEffect(() => {
    if (sendResponse) {
      isDirty(false);
      setRequestNumber((prevState) => prevState - 1);
      isUploading(true);
      if (resources.length > 0) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex].data);
        formData.append("model_name", "Origin");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
      if (deleteRecourse.length > 0) {
        deleteRecourse.forEach((r) => {
          setRequestNumber((prevState) => prevState - 1);
          deleteImage(
            { id: r },
            process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setValue("code", getInfo.data.code);
      setTranslatableInput({
        description: getInfo.data.description_transes,
        name: getInfo.data.name_transes,
      });
      setValue("type", getInfo.data.type);
      setValue("capacity", getInfo.data.capacity);
      setValue("responsible_name", {
        slug: getInfo.data.responsible_slug,
        full_name: getInfo.data.nursery_responsible,
        phone: getInfo.data.responsible_phone,
        community_initials: getInfo.data.community_initials,
      });
      setValue("latitude", getInfo.data.latitude);
      setValue("longitude", getInfo.data.longitude);
      setValue("community", {
        slug: getInfo.data.community_slug,
        name: getInfo.data.community_name,
      });
      setOldResource(getInfo.data.files);
      setValue("total_trees", getInfo.data.total_trees);
      setValue("phone", getInfo.data.responsible_phone);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    setValue("phone", resValue?.phone);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resValue?.phone]);

  useEffect(() => {
    if (fileResp) {
      setRequestNumber((prevState) => prevState - 1);
      setFileIndex(fileIndex + 1);
      if (resources.length - 1 >= fileIndex + 1) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex + 1].data);
        formData.append("model_name", "Origin");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResp]);
  useEffect(() => {
    if (requestNumber === 0) {
      reset();
      toast.success(
        `${
          getInfo
            ? `${t("Message.The record updated successfully")}`
            : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace("/setting/nurseries");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber]);

  const closeMap = () => {
    isLocationView(false);
  };

  const latLongChange = (newLat: number, newLong: number) => {
    isLocationView(false);
    clearErrors("latitude");
    clearErrors("longitude");
    const floatPattern = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
    if (floatPattern.test(String(newLat))) {
      setValue("latitude", newLat);
    } else {
      setError("latitude", {
        type: "pattern",
        message: `${t("Message.Invalid number")}`,
      });
    }
    if (floatPattern.test(String(newLong))) {
      setValue("longitude", newLong);
    } else {
      setError("longitude", {
        type: "pattern",
        message: `${t("Message.Invalid number")}`,
      });
    }
  };

  const handleAddLocation = () => {
    setIsAdd(true);
    isLocationView(true);
    isAlert(false);
  };

  let latErrorDisplayed = false;
  let longErrorDisplayed = false;

  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          name: { en: event.target.value, es: prevState.name.es },
        };
      });
      if (event.target.value !== "") {
        clearErrors("name_transes");
      }
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          name: { es: event.target.value, en: prevState.name.en },
        };
      });
      if (event.target.value !== "") {
        clearErrors("name_transes");
      }
    }
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            en: event.target.value,
            es: prevState.description.es,
          },
        };
      });
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            es: event.target.value,
            en: prevState.description.en,
          },
        };
      });
    }
  };

  const insertResources = (files: file[]) => {
    files.forEach((f) => {
      if (f.size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      }else if (!isSupportedFormat(f.name)){
        toast.error(t("Message.file supported formats"));
      } else {
        setRequestNumber((prevState) => prevState + 1);
        setResources((prevState) => [...prevState, f]);
      }
    });
  };

  const removeOldResource = (fileId: number) => {
    setDeleteResource((prevState) => [...prevState, fileId]);
    setOldResource((prevState) =>
      prevState.filter((prevItem) => prevItem.id !== fileId)
    );
    setRequestNumber((prevState) => prevState + 1);
  };

  const removeResource = (indexFile: number) => {
    setRequestNumber((prevState) => prevState - 1);
    setResources((prevState) =>
      prevState.filter((prevItem, index: number) => index !== indexFile)
    );
  };

  const addNurseries = (data: FormValues) => {
    const codeChange =
      !getInfo?.data?.is_code_editable && getInfo?.data?.code !== data.code;
    const typeChange =
      !getInfo?.data?.is_type_editable && getInfo?.data?.type !== data.type;
    if (
      (translatableInput.name.en.trim() !== "" &&
        translatableInput.name.es.trim() === "") ||
      (translatableInput.description.en.trim() !== "" &&
        translatableInput.description.es.trim() === "")
    ) {
      toast.error(
        `${t(
          "Message.While other languages have a translation, the default language cannot be empty."
        )}`
      );
    } else if (getInfo) {
      if (codeChange || typeChange) {
        if (codeChange) {
          toast.error(
            `${t(
              "Message.You can't edit the code, it is already used in a distributed tree order ID."
            )}`
          );
        }
        if (typeChange) {
          toast.error(
            `${t(
              "Message.You can't edit the nursery type, it has distributed or supplied trees."
            )}`
          );
        }
      } else {
        send({
          code: data.code,
          name_transes: translatableInput.name,
          type: data.type,
          capacity: data.capacity ? data.capacity : null,
          community: data.community?.slug ? data.community?.slug : null,
          responsible_name: data.responsible_name?.slug
            ? data.responsible_name?.slug
            : null,
          latitude: data.latitude ? data.latitude : null,
          longitude: data.longitude ? data.longitude : null,
          description_transes: translatableInput.description,
        });
      }
    } else {
      send({
        code: data.code,
        name_transes: translatableInput.name,
        type: data.type,
        capacity: data.capacity ? data.capacity : null,
        community: data.community?.slug ? data.community?.slug : null,
        responsible_name: data.responsible_name?.slug
          ? data.responsible_name?.slug
          : null,
        latitude: data.latitude ? data.latitude : null,
        longitude: data.longitude ? data.longitude : null,
        description_transes: translatableInput.description,
      });
    }
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addNurseries)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-default-text mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <div className="flex flex-wrap justify-between mt-7">
              {getInfo && getInfo?.data.type !== "ex" && (
                <div className="w-full sm:w-1/2 sm:pr-2">
                  <p className="text-sm  text-default-text mb-[6px]">
                    {t("Tree nurseries.Total trees")}
                  </p>
                  <input
                    {...register("total_trees")}
                    type="number"
                    readOnly
                    disabled
                    className="input-disabled pl-4 py-3 text-sm bg-ph-bg-gray placeholder-ph-light-gray"
                    placeholder={t("Tree nurseries.Total trees") + ""}
                  />
                </div>
              )}
              <div
                className={`w-full sm:w-1/2 ${
                  getInfo && getInfo?.data.type !== "ex" ? "sm:pl-2" : "sm:pr-2"
                }`}
              >
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Register.Code")} *
                </p>
                <input
                  {...register("code", {
                    required: `${t("Message.Required field")}`,
                    maxLength: {
                      value: 4,
                      message: `${t("Message.Code must be 4 characters.")}`,
                    },
                    minLength: {
                      value: 4,
                      message: `${t("Message.Code must be 4 characters.")}`,
                    },
                  })}
                  maxLength={4}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Register.Code") + ""}
                />

                {errors.code && (
                  <p className="error-text">{errors.code.message}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tree Species.Name")} * <LanguagePoint lang={lang} />
                </p>
                <input
                  name="name_transes"
                  onChange={nameChange}
                  value={
                    lang === "EN"
                      ? translatableInput.name.en
                      : translatableInput.name.es
                  }
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Tree nurseries.Nursery name") + ""}
                />
                {errors.name_transes && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2">
                <p
                  className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Tree nurseries.Nursery type")} *
                </p>
                <Controller
                  control={control}
                  name="type"
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && `${t("Tree nurseries.Nursery type")}`}
                      </InputLabel>
                      <ThemeProvider theme={selectLangTheme}>
                        <Fragment>
                          <Select
                            label="Choose Language"
                            style={{ height: "46px" }}
                            IconComponent={ExpandMoreIcon}
                            variant="outlined"
                            value={value || null}
                            onChange={(e) => {
                              onChange(e as ChangeEvent<Element>);
                            }}
                            sx={{
                              border: "1px solid #eeecec",
                              borderRadius: "4px",
                              fontSize: "0.857rem",
                              lineHeight: "1.25rem",
                              "& fieldset": { border: "none" },
                            }}
                          >
                            <MenuItem value="in">
                              {t("Tree nurseries.Internal")}
                            </MenuItem>
                            <MenuItem value="ex">
                              {t("Tree nurseries.External")}
                            </MenuItem>
                          </Select>
                        </Fragment>
                      </ThemeProvider>
                    </FormControl>
                  )}
                />
                {errors.type && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between md:mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p
                  className={`text-default-text text-sm mb-[6px] mt-7 md:mt-0`}
                >
                  {t("Tree nurseries.Capacity")}
                </p>
                <NumberField
                  onChange={() => {}}
                  inputStyle="py-[13.5px] placeholder-ph-light-gray"
                  getValues={getValues}
                  setValue={setValue}
                  name="capacity"
                  placeholder={t("Tree nurseries.Capacity") + ""}
                  register={register("capacity")}
                  readOnl={false}
                />
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2">
                <p
                  className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Plantings.Community")}
                </p>
                <CustomAutocomplete
                  control={control}
                  name="community"
                  selectOptions={communities.community}
                  placeholder={t("Plantings.Community")}
                  getOptionLabel={(option) => option.name}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-1/2 pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("AddPlanting.Latitude")}
                </p>
                <input
                  {...register("latitude", {
                    pattern: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/,
                  })}
                  onChange={() => {
                    clearErrors("latitude");
                  }}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("AddPlanting.Latitude") + ""}
                />
                {errors.latitude?.type === "pattern" && !latErrorDisplayed && (
                  <>
                    {(latErrorDisplayed = true)}
                    <p className="error-text">{t("Message.Invalid number")}</p>
                  </>
                )}
              </div>
              <div className="w-1/2 pl-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("AddPlanting.Longitude")}
                </p>
                <input
                  {...register("longitude", {
                    pattern: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/,
                  })}
                  onChange={() => {
                    clearErrors("longitude");
                  }}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("AddPlanting.Longitude") + ""}
                />
                {errors.longitude?.type === "pattern" &&
                  !longErrorDisplayed && (
                    <>
                      {(longErrorDisplayed = true)}
                      <p className="error-text">
                        {t("Message.Invalid number")}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <button
              onClick={() => {
                isLocationView(true);
                setIsAdd(false);
              }}
              type="button"
              className="text-sm text-ph-btn mb-[6px] mt-5"
            >
              <FontAwesomeIcon className="pr-1" icon={faLocationDot} />
              {t("Register.Edit location on the map")}
            </button>

            <div className="flex flex-wrap justify-between md:mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p
                  className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Tagging tool.Responsible")}
                </p>
                <CustomAutocomplete
                  control={control}
                  name="responsible_name"
                  selectOptions={responsible.responsible}
                  placeholder={t("Tagging tool.Responsible")}
                  getOptionLabel={(option) =>
                    option?.full_name +
                      (option?.full_name
                        ? " (" + option?.community_initials + ")"
                        : "") || option?.slug
                  }
                />
              </div>
              {resValue?.phone && (
                <div className="w-full sm:w-1/2 sm:pl-2">
                  <p
                    className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                  >
                    {t("Tree nurseries.Responsible phone")}
                  </p>
                  <input
                    {...register("phone")}
                    type="text"
                    readOnly
                    className="input-disabled pl-4 py-3 text-sm placeholder-ph-light-gray"
                    placeholder={t("Tree nurseries.Responsible phone") + ""}
                  />
                </div>
              )}
            </div>
            <p className="text-sm text-default-text mt-7 mb-[6px]">
              {t("Tree nurseries.Resources")}
            </p>
            <DragDropField
              files={resources}
              format="jpg, jpeg, png, txt, doc, docx, pdf, xls, xlsx (max 10 MB)"
              insertFile={insertResources}
              removeFile={removeResource}
            />

            {oldResource.map((or) => (
              <OldFile
                address={or.file_address}
                id={or.id}
                removeOldFile={removeOldResource}
                key={or.id}
                delete={true}
              />
            ))}
            <p className="text-sm text-default-text mt-7 mb-[6px]">
              {t("Tree nurseries.Facilities and services")}{" "}
              <LanguagePoint lang={lang} />
            </p>
            <textarea
              onChange={descriptionChange}
              value={
                lang === "EN"
                  ? translatableInput.description.en
                  : translatableInput.description.es
              }
              rows={7}
              className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
              placeholder={t("Tree Species.Description") + ""}
            />
          </ThemeProvider>
          <button
            onClick={() => {
              if (
                translatableInput.name.es.trim() === "" &&
                translatableInput.name.en.trim() === ""
              ) {
                setError("name_transes", {
                  type: "custom",
                  message: `${t("Message.Required field")}`,
                });
              }
            }}
            type="submit"
            className={`${
              uploading ? "green-btn-disable" : "green-btn"
            } text-sm  px-6 py-2 mt-5 float-right font-medium`}
            disabled={uploading ? true : false}
          >
            {uploading ? t("Message.Wait for upload files") : textButton}
          </button>
        </form>
      )}
      {locationView && (
        <LocationPage
          header={t("Tree nurseries.Update tree nursery location")}
          lat={getValues("latitude")}
          long={getValues("longitude")}
          isAdd={isAdd}
          closeMap={closeMap}
          latLongChange={latLongChange}
        />
      )}
      {alert && (
        <GeneralAlert
          alert={true}
          handleConfirm={handleAddLocation}
          handleCancel={() => {}}
          body={t(
            "Message.The latitude and longitude were not read successfully"
          )}
          confirmText={t("Message.Add location") + ""}
        />
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddTreeNurseriesForm;
